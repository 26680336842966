<template>
  <div :class="['flex menu', { blur: u_store.isBlurred }]">
    <div
      :class="[
        'text-h1',
        'pointer',
        'm-r-xs',
        'btn',
        u_store.darkMode ? 'darkOptions' : 'light',
      ]"
      @click="
        $router.push({
          name: props.location === 'menu' ? 'HomeView' : 'MenuPage',
        })
      "
      @touchstart.prevent="unBlurThenGo()"
    >
      {{ props.location === 'menu' ? 'HOME' : 'MENU' }}
    </div>
    <div
      @click="flipOrder"
      class="flipper"
      @touchstart.prevent="unBlurThenFlip()"
    >
      <TransitionGroup name="flip-list" tag="div">
        <div
          v-for="(lang, index) in u_store.langSwitch"
          :key="lang"
          class="lang pointer archivo-bold"
          :style="{ fontSize: fontSize(index) }"
          :class="u_store.darkMode ? 'darkOptions' : 'light'"
        >
          {{ lang.toUpperCase() }}
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup>
/* imports */
// vue
import { defineProps, ref, defineEmits, onMounted, onUnmounted } from 'vue'
//
import { uiStore } from '@/store/uiStore'
import { useRouter } from 'vue-router'

const u_store = uiStore()
const router = useRouter()
const props = defineProps({
  location: String,
})
const emit = defineEmits(['flip'])

function unBlurThenGo() {
  if (u_store.mask === true) return
  router.push({ name: props.location === 'menu' ? 'HomeView' : 'MenuPage' })
}

function unBlurThenFlip() {
  if (u_store.mask === true) return
  flipOrder()
}

const flipOrder = () => {
  u_store.lang = u_store.lang === 'pl' ? 'en' : 'pl'
  u_store.langSwitch.reverse()
  emit('flip')
}

// get info if screan is less thn 492px
const isMobile = ref(window.innerWidth < 492)
const isTablet = ref(window.innerWidth > 492 && window.innerWidth < 768)
const isLaptop = ref(window.innerWidth > 768 && window.innerWidth < 1520)
const isDesktop = ref(window.innerWidth > 1520)

const updateScreenSize = () => {
  isMobile.value = window.innerWidth < 492
  isTablet.value = window.innerWidth > 492 && window.innerWidth < 768
  isLaptop.value = window.innerWidth > 768 && window.innerWidth < 1520
  isDesktop.value = window.innerWidth > 1520
}

onMounted(() => {
  window.addEventListener('resize', updateScreenSize)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateScreenSize)
})

const fontSize = (index) => {
  if (isMobile.value) {
    return index === 0 ? '15px' : '9px'
  }
  if (isTablet.value) {
    return index === 0 ? '16px' : '13px'
  }
  if (isLaptop.value) {
    return index === 0 ? '16px' : '13px'
  }
  if (isDesktop.value) {
    return index === 0 ? '20px' : '14px'
  }
}
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.1s;
}
.lang {
  display: flex;
}

.menu {
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (max-width: 492px) {
  .menu {
    width: 120px;
    margin-right: 2px;
  }
  .text-h1 {
    font-size: 30px;
  }
}

// @media screen and (min-width: 493px) and (max-width: 1220px) {
//   .text-h1 {
//     font-size: 35.5px;
//     margin: 0;
//   }
// }
// @media screen and (min-width: 1221px) {
//   .text-h1 {
//     font-size: 40px;
//     margin: 0;
//   }
// }
// @media screen and (min-width: 1521px) {
//   .text-h1 {
//     font-size: 40px;
//     margin: 0;
//   }
// }
@media screen and (min-width: 493px) and (max-width: 1220px) {
  .text-h1 {
    font-size: 35.5px;
  }
  .text-lang-btn {
    font-size: 2px;
  }
  .flipper {
    margin-left: 2px;
    width: 20px;
  }
  .menu {
    margin-right: 2px;
  }
}

@media screen and (min-width: 1221px) {
  .text-h1 {
    font-size: 35.5px;
  }
  .text-lang-btn {
    font-size: 14px;
  }
  .flipper {
    margin-left: 12px;
    width: 20px;
  }
}

@media screen and (min-width: 1521px) {
  .text-h1 {
    font-size: 40px;
    margin: 0;
  }
}
</style>
