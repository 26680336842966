<template>
  <div class="home_container" v-if="u_store.isLoaded">
    <moon-btn class="moon" />
    <menu-btn class="menu" @flip="maxLength = 168" />
    <author-btn class="auth" />
    <!-- sec row -->
    <p
      class="secondary archivo-bold title-line"
      :class="u_store.darkMode ? 'darkTitle' : 'light'"
      v-if="!imgMagnification && collection"
    >
      {{ collection[`name_${u_store.lang}`] }}
    </p>

    <div v-if="!imgMagnification && collection" class="description">
      <p
        v-for="(p, index) in displayedDescription"
        :key="index"
        :class="[
          { 'trim-text': !showFullText },
          'text',
          'secondary',
          'archivo-medium',
          'desc-line',
          'pre-line',
          'p',
          'm',
          u_store.darkMode ? 'darkDescription' : 'light',
        ]"
      >
        {{ p }}
      </p>
      <p

        @click="toggleShowFullText"
        class="pointer archivo-it textEnlarger"
        :class="u_store.darkMode ? 'darkMore' : 'light'"
        :style="`${ totalLength < 168 ? 'opacity:0;  cursor:default;' : ''}`"
      >
        {{
          showFullText
            ? u_store.sysT[u_store.lang].less
            : u_store.sysT[u_store.lang].more
        }}
      </p>
    </div>

    <div class="mobilePic">
      <img
        v-for="(pic, index) in collection?.collection"
        :key="index"
        :src="pic.url"
        class="m-b-md"
        style="width: 330px; object-fit: contain"
      />
    </div>

    <div
      v-if="collection && !showFullText"
      :class="[imgMagnification ? 'swiperLarge' : 'swiperStandard']"
      style="position: relative"
    >
      <swiper
        ref="swiperRef"
        class="mySwiper"
        :slides-per-view="1"
        :space-between="300"
        @slideChange="
          (ev) => {
            selectPic(collection.collection[ev.realIndex].url, ev.realIndex)
            scrollTo(itemRefs[ev.realIndex])
          }
        "
        @click="[scrollToAfterMinfication()]"
        :navigation="true"
        style="cursor: grab"
      >
        <swiper-slide v-for="(el, index) in collection.collection" :key="index">
          <img :src="el.url" />
        </swiper-slide>
      </swiper>

      <div
        class="left-arrow tablet"
        :class="u_store.darkMode ? 'darkOptions' : 'light'"
        @click="[goToPreviousImage(), scrollToLeft(itemRefs[currentIndex])]"
        :style="`${
          imgMagnification
            ? 'position:absolute;left: 42%; bottom:0'
            : 'position: absolute; left: 0px; top: 45%; z-index: 10'
        };
        ${currentIndex > 0 ? '' : 'opacity: 0.4;'}
        `"
        style="border: 1px;"
      />
      <div
        class="right-arrow tablet"
        :class="u_store.darkMode ? 'darkOptions' : 'light'"
        @click="[goToNextImage(), scrollToRight(itemRefs[currentIndex])]"
        :style="`
    ${imgMagnification ? 'position:absolute; left:53%; bottom:0' : 'position: absolute; right: 0px; top: 45%; z-index: 10'};

          ${currentIndex < collection.collection.length - 1 ? '' : 'opacity: 0.4;'}
        `"
      />
    </div>

    <!-- fourth row -->
    <div
      class="carousel"
      :style="`width: ${totalImagesLength + 'px'} max-width: 100%`"
      v-show="!imgMagnification && collection && !showFullText"
    >
      <div class="box" ref="carousel" @wheel.prevent="handleWheelTab">
        <div
          :ref="setRef"
          class="card"
          v-for="(pic, index) in collection.collection"
          :key="index"
        >
          <div
            :class="{
              dot: selectedPic === pic.url,
              'not-selected': selectedPic !== pic.url,
            }"
            style="height: 10px; width: 10px; margin-left: 100%"
          />
          <img
            class="card"
            :src="pic.url"
            @click="selectPic(pic.url, index), scrollTo(itemRefs[index])"
            style="margin: auto 0; height: 80px; object-fit: cover"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/* imports */
// vue
import { ref, computed, onMounted, watch, onUnmounted } from 'vue'
// libraries
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router'
import { Swiper, SwiperSlide } from 'swiper/vue'
// store
import { uiStore } from '@/store/uiStore'
// components
import MenuBtn from '@/components/MenuBtn.vue'
import MoonBtn from '@/components/MoonBtn.vue'
import AuthorBtn from '@/components/AuthorBtn.vue'
// styles
import 'swiper/css'

/* script */
interface RouteParams {
  anchor: string
}
const route = useRoute() as RouteLocationNormalizedLoaded & {
  params: RouteParams
}
const anchor = route.params.anchor
const u_store = uiStore()
const collection = ref(u_store.albums.find((el) => el.anchor === anchor))
const selectedPic = ref<null | string>(null)
const currentIndex = ref(0)
const selectedPicIndex = ref(0)
const maxLength = ref(168)
const carousel = ref(null)
const imgMagnification = ref(false)
const showFullText = ref(false)
const itemRefs = ref<HTMLElement[]>([])
const swiperRef = ref(null)
const lastMove = ref(0)
const setRef = (el: HTMLElement | undefined) => {
  if (el && !itemRefs.value.includes(el)) {
    itemRefs.value.push(el)
  }
}
const totalLength = computed(() => {
  if(!collection.value) return 0
  return collection.value[`description_${u_store.lang}`].join('').length
})

onMounted(() => {
  if (collection.value) {
    selectedPic.value = collection.value.collection[0].url
  }
})

watch(selectedPic, (newVal, oldVal) => {
  if (newVal && oldVal) {
    if (collection.value) {
      const newIndex = collection.value.collection.findIndex(
        (el) => el.url === newVal,
      )
      const oldIndex = collection.value.collection.findIndex(
        (el) => el.url === oldVal,
      )
      lastMove.value = newIndex > oldIndex ? 1 : -1
    }
  }
})

function scrollToAfterMinfication() {
  if (imgMagnification.value === true) {
    setTimeout(() => {
      selectedPic.value = collection.value?.collection[currentIndex.value].url
        ? collection.value?.collection[currentIndex.value].url
        : null
      scrollTo(itemRefs.value[currentIndex.value])
    }, 10)
  }
  imgMagnification.value = !imgMagnification.value
}

const totalImagesLength = computed(() => {
  if (!collection.value) return 0
  const newHeight = 130
  return collection.value.collection
    .map((el) => {
      const originalWidth = el.dimensions.width
      const originalHeight = el.dimensions.height
      const newWidth = (newHeight / originalHeight) * originalWidth
      return newWidth
    })
    .reduce((acc, current) => acc + current, 0)
})

const handleWheelTab = (event: WheelEvent) => {
  if (carousel.value) {
    event.preventDefault()
    carousel.value.scrollLeft += event.deltaY
  }
}

const scrollTo = (element: HTMLElement) => {
  currentIndex.value = itemRefs.value.indexOf(element)
  if (element && element.scrollIntoView) {
    const index = itemRefs.value.indexOf(element)
    let targetIndex = index
    if (index === 0) {
      targetIndex = index
    }
    if (index === itemRefs.value.length - 1) {
      targetIndex = index
    }
    if (index > 0 && index < itemRefs.value.length - 1) {
      targetIndex = index + lastMove.value
    }
    itemRefs.value[targetIndex].scrollIntoView({
      behavior: 'instant',
      block: 'end',
      inline: 'nearest',
    })
  }
}

const scrollToView = (targetIndex: number) => {
  if (targetIndex >= 0 && targetIndex < itemRefs.value.length) {
    itemRefs.value[targetIndex].scrollIntoView({
      behavior: 'smooth', // Changed to 'smooth' for a smoother scrolling experience
      block: 'end',
      inline: 'nearest',
    })
  }
}

const scrollToRight = (element: HTMLElement) => {
  const index = itemRefs.value.indexOf(element)
  if (index < 0 || itemRefs.value.length === 0) return // Error handling

  const isLastElement = index === itemRefs.value.length - 1
  const nextIndex = isLastElement
    ? index
    : Math.min(index + 2, itemRefs.value.length - 1)
  scrollToView(nextIndex)
}

const scrollToLeft = (element: HTMLElement) => {
  const index = itemRefs.value.indexOf(element)
  if (index < 0 || itemRefs.value.length === 0) return // Error handling

  const isFirstElement = index === 0
  const targetIndex = isFirstElement ? 0 : Math.max(index - 3, 0)
  scrollToView(targetIndex)
}

function selectPic(pic: string, index: number) {
  if (!collection.value) return
  swiperRef.value.$el.swiper.slideTo(index)
  selectedPic.value = pic
  collection.value.selectedPicture = pic
  selectedPicIndex.value = index
  currentIndex.value = index
}

function updateIndexAndSelectPic(direction: 'prev' | 'next') {
  if (!collection.value) return

  const collectionLength = collection.value.collection.length
  // console.log('is sepcial case');
  let newIndex = currentIndex.value

  if (direction === 'prev') {
    // console.log('prev', currentIndex.value, newIndex);
    swiperRef.value.$el.swiper.slidePrev()
    newIndex =  Math.max(newIndex - 1, 0)
    // console.log('prev', currentIndex.value, newIndex);
  } else if (direction === 'next') {
    // console.log('next', currentIndex.value, newIndex);
    swiperRef.value.$el.swiper.slideNext()
    newIndex =  Math.min(newIndex + 1, collectionLength - 1)
      // console.log('prev', currentIndex.value, newIndex);
  }

  if (newIndex !== currentIndex.value) {
    // console.log(newIndex);
    selectPic(collection.value.collection[newIndex].url, newIndex)
  }
}

function goToPreviousImage() {
  updateIndexAndSelectPic('prev')
}

function goToNextImage() {
  updateIndexAndSelectPic('next')
}

// function selectPic(pic: string, index: number) {
//   if (!collection.value) return
//   swiperRef.value.$el.swiper.slideTo(index)
//   selectedPic.value = pic
//   collection.value.selectedPicture = pic
//   selectedPicIndex.value = index
//   currentIndex.value = index
// }

// function goToPreviousImage() {
//   swiperRef.value.$el.swiper.slidePrev()
//   if (currentIndex.value > 0) {
//     if (collection.value) {
//       if (collection.value.collection.length === 3) {
//         selectPic(
//           collection.value.collection[currentIndex.value].url,
//           currentIndex.value,
//         )
//         return
//       }
//       currentIndex.value -= 1
//     }
//   }
//   if (collection.value)
//     selectPic(
//       collection.value?.collection[currentIndex.value].url,
//       currentIndex.value,
//     )
// }

// function goToNextImage() {
//   swiperRef.value.$el.swiper.slideNext()
//   if (collection.value) {
//     if (collection.value.collection.length === 3) {
//       selectPic(
//         collection.value.collection[currentIndex.value].url,
//         currentIndex.value,
//       )
//       return
//     }

//     if (currentIndex.value < collection.value.collection.length - 1) {
//       currentIndex.value += 1
//     }
//     selectPic(
//       collection.value.collection[currentIndex.value].url,
//       currentIndex.value,
//     )
//   }
// }

const displayedDescription = computed((): string[] => {
  if (!collection.value) {
    return []
  }
  if (showFullText.value) {
    return collection.value[`description_${u_store.lang}`]
  }
  if(collection.value[`description_${u_store.lang}`].join('').length < maxLength.value){
    return collection.value[`description_${u_store.lang}`]

  }

  if (!showFullText.value) {
    const reducedDescription: string[] = []
    collection.value[`description_${u_store.lang}`].forEach((p) => {
      if (p.length > maxLength.value) {
        // console.log('maxLenght', maxLength.value);
        let par = p.slice(0, maxLength.value)
        if (par !== '') {
          reducedDescription.push(par)
          maxLength.value =
            maxLength.value -
            reducedDescription[reducedDescription.length - 1].length
        }
      }
    })

    reducedDescription[reducedDescription.length - 1] += '...'
    // console.log(reducedDescription);
    return reducedDescription
  } else {
    return []
  }
})

function toggleShowFullText() {
  if(totalLength.value < 168) return
  showFullText.value = !showFullText.value
  maxLength.value = 168
}
window.addEventListener('keydown', handleKeyDown)
// Funkcja obsługująca zdarzenie keydown
function handleKeyDown(event: KeyboardEvent) {
  switch (event.key) {
    case 'ArrowLeft':
      goToPreviousImage()
      scrollToLeft(itemRefs.value[currentIndex.value])
      break
    case 'ArrowRight':
      goToNextImage()
      scrollToRight(itemRefs.value[currentIndex.value])
      break
  }
}

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyDown)
})
</script>

<style scoped>
div.home_container {
  margin: 0 auto;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  display: none;
}
.pre-line {
  white-space: pre-line;
}

.dot {
  /* background url */
  background-image: url('~@/assets/tick.svg');
  background-size: cover;
  width: 100%;
  height: 100%;
}

.darkTitle {
  color: #777777;
}
.darkDescription {
  color: #a5a5a5;
}

.textEnlarger {
  color: #a5a5a5;
}
.textEnlarger:hover {
  color: #303030;
}
.darkMore {
  color: #727272;
}
.darkMore:hover {
  color: #000000;
}

.left-arrow {
  background: url('~@/assets/arrow.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1);
}
.left-arrow:hover {
  background-image: url('~@/assets/arrow_active.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1);
}

.right-arrow {
  background: url('~@/assets/arrow.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}
.right-arrow:hover {
  background-image: url('~@/assets/arrow_active.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}

.darkOptions.left-arrow {
  background-image: url('~@/assets/arrow_dark.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1);
}
.darkOptions.left-arrow:hover {
  background-image: url('~@/assets/arrow_active_dark.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1);
}

.darkOptions.right-arrow {
  background-image: url('~@/assets/arrow_dark.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}
.darkOptions.right-arrow:hover {
  background-image: url('~@/assets/arrow_active_dark.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}
.right-arrow {
  width: 40px;
  height: 40px;
}
.left-arrow {
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 492px) {
  .home_container {
    display: grid;
    grid-template-columns: 165px 165px;
    grid-template-rows: 15px 30px 15px;
    row-gap: 10px;
    width: 330px;
  }
  .moon,
  .menu {
    grid-row: 2;
  }
  .moon {
    grid-column: 1 / 2;
    justify-self: start;
  }
  .menu {
    grid-column: 2 / 3;
    justify-self: end;
  }
  .auth {
    grid-row: 3;
    grid-column: 1 / 3;
    align-self: start;
  }
  .title-line {
    grid-row: 4;
    grid-column: 1 / 3;
    font-size: 19px;
    margin-bottom: 0px;
    z-index: 3;
    word-wrap: break-word; /* Stare przeglądarki */
    overflow-wrap: break-word; /* Nowe przeglądarki */
  }
  .description {
    z-index: 3;
    grid-column: 1 / 3;
    grid-row: 5;
    line-height: 1.3;
    justify-self: start;
    word-wrap: break-word; /* Stare przeglądarki */
    overflow-wrap: break-word; /* Nowe przeglądarki */
  }
  .textEnlarger {
    margin-top: 1px;
  }
  .mobilePic {
    z-index: 3;
    grid-row: 6;
    width: 330px;
    height: 100%;
    grid-column: 1 /3;
  }
  .swiperLarge {
    display: none;
  }
  .swiperStandard {
    display: none;
  }
  .carousel {
    display: none;
  }
}

@media screen and (min-width: 493px) and (max-width: 1220px) {
  /* 28px 32px 50px 48px 28px; */
  .home_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 28px 42px 50px 48px 35px;
    width: 100%;
    max-width: 490px;
    min-width: 490px;
    column-gap: 84px;
    margin: 0 auto;
    position: relative;
  }
  .moon,
  .menu,
  .auth {
    grid-column: 1 / 3;
  }
  .moon {
    grid-row: 2;
    justify-self: end;
  }
  .menu {
    grid-row: 3;
    align-self: center;
    justify-self: end;
  }
  .auth {
    grid-row: 4;
    align-self: end;
    justify-self: start;
  }
  .title-line {
    z-index: 3;
    grid-row: 6;
    grid-column: 1 /3;
    font-size: 20px;
    line-height: 1;
    margin-top: 0;
    align-self: end;
    word-wrap: break-word; /* Stare przeglądarki */
    overflow-wrap: break-word; /* Nowe przeglądarki */
  }
  .textEnlarger {
    margin-top: 3px;
  }
  .description {
    z-index: 3;
    grid-column: 1 / 3;
    grid-row: 7;
    width: 100%;
    max-width: 490px;
    min-width: 490px;
    line-height: 1.4;
    justify-self: start;
    word-wrap: break-word;
    overflow-wrap: break-word;

  }
  .tablet {
    display: none;
  }
  .swiperLarge {
    z-index: 3;
    grid-row: 6;
    grid-column: 1 / 3;
    display: flex;
    justify-content: space-between;
  }
  .swiperStandard {
    z-index: 3;
    grid-column: 1 / 3;
    grid-row: 8;
    display: flex;
    justify-content: space-between;
  }
  .swiperStandard .swiper-slide img {
    /* display: block;
    height: 300px;
    object-fit: cover; */
    display: block;
    /* height: 460px; */
    max-width: 100%;
    max-height: 420px;
    object-fit: cover;
  }
  .swiperLarge .swiper-slide img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  .carousel {
    z-index: 3;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 70px;
    max-width: 90vw;
    height: 110px;
    grid-row: 9;
    display: flex;
    margin: 0 auto;
  }
  .box {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    /* width: 100%; */
    /* margin: 0 auto; */
  }
  .card {
    margin: 0px 20px;
    height: 110px;
    cursor: pointer;
    scroll-margin-right: 10px;
  }
  .mobilePic {
    display: none;
  }
}

@media screen and (min-width: 1221px) {
  .home_container {
    display: grid;
    width: 100%;
    max-width: 1640px;
    min-width: 1210px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 40px 80px 20px;
    grid-column-gap: 50px;
  }
  .auth {
    grid-column: 2 / 5;
    justify-self: start;
  }
  .menu {
    grid-column: 4 / 5;
    justify-self: end;
  }
  .moon {
    grid-column: 5 / 6;
    justify-self: center;
  }
  .auth,
  .menu,
  .moon {
    grid-row: 2;
    color: #c9c9c9;
    align-self: center;
  }
  .title-line {
    z-index: 3;
    grid-row: 4;
    grid-column: 2 / 5;
    font-size: 26px;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 10px;
    align-self: end;
    word-wrap: break-word; /* Stare przeglądarki */
    overflow-wrap: break-word; /* Nowe przeglądarki */
  }
  .description {
    font-size: 18px;
    z-index: 3;
    grid-column: 2 / 5;
    grid-row: 6;
    justify-self: start;
    align-self: start;
    line-height: 1.4;
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  .textEnlarger {
    margin-top: 3px;
  }
  .swiperLarge {
    z-index: 3;
    grid-row: 6;
    grid-column: 2 / 5;
    height: 580px;
  }
  .swiperStandard {
    z-index: 3;
    grid-column: 2 / 5;
    grid-row: 7;
    margin: 0 0 40px 0;
    display: flex;
    justify-content: space-between;
  }
  .swiperStandard .swiper-slide img {
    display: block;
    height: 260px;
    object-fit: cover;
  }
  .swiperLarge .swiper-slide img {
    display: block;
    height: 500px;
    object-fit: cover;
  }
  .mobilePic {
    display: none;
  }
  .carousel {
    z-index: 3;
    grid-row: 8;
    grid-column: 2 / 5;
    max-width: 100%;
    height: 110px;
    display: flex;
    margin: 0 auto;
  }
  .box {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  .card {
    margin: 0px 20px;
    height: 110px;
    cursor: pointer;
    scroll-margin-right: 10px;
  }
}
@media screen and (min-width: 1520px) {
  .home_container {
    grid-template-rows: 50px 100px 40px;
  }
  .swiperLarge {
    height: 760px;
  }
  .swiperStandard .swiper-slide img {
    display: block;
    height: 370px;
    object-fit: cover;
  }
  .swiperLarge .swiper-slide img {
    display: block;
    height: 650px;
    object-fit: cover;
  }
  .title-line {
    grid-row: 4;
    grid-column: 2 / 5;
    font-size: 34px;
    line-height: 1.3;
    margin-top: 0;
    align-self: end;
    z-index: 3;
    word-wrap: break-word; /* Stare przeglądarki */
    overflow-wrap: break-word; /* Nowe przeglądarki */
  }
  .description{
    max-width: 100%;
    word-wrap: break-word; /* Stare przeglądarki */
    overflow-wrap: break-word; /* Nowe przeglądarki */
  }
  .desc-line {
    font-size: 22px;
  }
  .swiperStandard {
    margin: 0 0 70px 0;
  }
}
</style>
