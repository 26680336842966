import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import { createClient } from 'contentful'

const client = createClient({
  space: 'snbb9hm9juuz',
  accessToken: 'PBHyD584uv2uBAYcNYN2z-38QNrr7rujI09DrmHe07s',
})

const app = createApp(App)
app.config.globalProperties.$contentful = client
app.use(router)
app.use(createPinia())
app.mount('#app')
