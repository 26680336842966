<template>
  <div class="home_container">
    <moon-btn class="moon" />
    <menu-btn class="menu" />
    <author-btn class="auth" />
    <!-- @slide-end="nextPic(1)" -->
    <swiper
      v-if="u_store.shop"
      :items-to-show="1"
      class="swiperStandard"
      ref="swiperRef"
      @slideChange="currentImg = $event.activeIndex"
    >
      <swiper-slide v-for="(item, index) in u_store.shop" :key="index">
        <img :src="item.image" />
      </swiper-slide>
    </swiper>
    <div v-if="u_store.shop" class="title">
      <p
        class="secondary archivo-medium common-styles pre-line"
        :class="u_store.darkMode ? 'darkTitle' : 'light'"
        v-for="(p, index) in u_store.shop[currentImg][`name_${u_store.lang}`]"
        :key="index"
      >
        {{ p }}
      </p>
    </div>

    <div v-if="u_store.shop" class="details">
      <p
        class="secondary archivo-medium common-styles pre-line m"
        :class="u_store.darkMode ? 'darkDescription' : 'light'"
        v-for="(p, index) in u_store.shop[currentImg][
          `description_${u_store.lang}`
        ]"
        :key="index"
      >
        {{ p }}
      </p>
    </div>

    <div class="flex justify-center arrows" style="gap: 40px">
      <div
        class="left-arrow"
        @click="nextPic(-1)"
        :class="u_store.darkMode ? 'darkOptions' : 'light'"
        :style="`
        ${currentImg > 0 ? '' : 'opacity: 0.4;'}
        `"
      />
      <div
        class="right-arrow"
        @click="nextPic(1)"
        :class="u_store.darkMode ? 'darkOptions' : 'light'"
        :style="`
          ${currentImg < u_store.shop.length - 1 ? '' : 'opacity: 0.4;'}
        `"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
/* imports */
// vue
import { ref } from 'vue'
// libraries
// store
import { uiStore } from '@/store/uiStore'
// components
import MenuBtn from '@/components/MenuBtn.vue'
import MoonBtn from '@/components/MoonBtn.vue'
import AuthorBtn from '@/components/AuthorBtn.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

/* script */
const u_store = uiStore()
const currentImg = ref(0)
const swiperRef = ref(null)

function nextPic(move: 1 | -1) {
  if (move === 1) {
    currentImg.value =
      u_store.shop.length - 1 === currentImg.value ? currentImg.value : move
    swiperRef.value.$el.swiper.slideTo(currentImg.value)
  }
  if (move === -1) {
    currentImg.value =
      currentImg.value === 0 ? 0 : (currentImg.value = currentImg.value + move)
    swiperRef.value.$el.swiper.slideTo(currentImg.value)
  }
}
</script>

<style scoped>
div.home_container {
  margin: 0 auto;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pre-line {
  white-space: pre-line;
}

.left-arrow {
  background: url('~@/assets/arrow.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1);
}
.left-arrow:hover {
  background-image: url('~@/assets/arrow_active.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1);
}

.right-arrow {
  background: url('~@/assets/arrow.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}
.right-arrow:hover {
  background-image: url('~@/assets/arrow_active.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}

.darkOptions.left-arrow {
  background-image: url('~@/assets/arrow_dark.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1);
}
.darkOptions.left-arrow:hover {
  background-image: url('~@/assets/arrow_active_dark.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  transform: scaleX(-1);
}

.darkOptions.right-arrow {
  background-image: url('~@/assets/arrow_dark.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}
.darkOptions.right-arrow:hover {
  background-image: url('~@/assets/arrow_active_dark.svg');
  background-size: 20px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}
.right-arrow {
  width: 40px;
  height: 40px;
}
.left-arrow {
  width: 40px;
  height: 40px;
}

.darkTitle {
  color: #a5a5a5;
}
.darkDescription {
  color: #a5a5a5;
}

@media screen and (max-width: 492px) {
  .carousel__slide {
    padding: 10px;
  }
  /* stop horizontal scorll */
  .home_container {
    display: grid;
    grid-template-columns: 165px 165px;
    grid-template-rows: 15px 30px 15px;
    row-gap: 10px;
    width: 330px;
  }
  .moon,
  .menu {
    grid-row: 2;
  }
  .moon {
    grid-column: 1 / 2;
    justify-self: start;
  }
  .menu {
    grid-column: 2 / 3;
    justify-self: end;
  }
  .auth {
    grid-row: 3;
    grid-column: 1 / 3;
    align-self: start;
  }
  .swiperStandard {
    z-index: 3;
    grid-row: 4;
    grid-column: 1/3;
    margin-top: 22px;
    max-width: 330px;
    display: flex;
    justify-content: space-between;
  }
  .swiperStandard .swiper-slide img {
    display: block;
    width: 100%;
    height: 210px;
    object-fit: cover;
  }

  .title {
    z-index: 3;
    margin-top: 0px;
    font-size: 14px;
    line-height: 1.4;
    grid-column: 1/3;
    grid-row: 5;
    justify-self: start;
  }
  .details {
    z-index: 3;
    grid-column: 1/3;
    grid-row: 6;
    margin-top: 0px;
    line-height: 1.5;
    font-size: 14px;
    justify-self: start;
  }
  .arrows {
    z-index: 3;
    display: none;
  }
}

@media screen and (min-width: 493px) and (max-width: 1220px) {
  .phone {
    display: none;
  }
  /*     grid-template-rows: 28px 32px 50px 48px 28px; */
  .home_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 28px 42px 50px 48px 28px 310px;
    width: 100%;
    max-width: 490px;
    min-width: 490px;
    column-gap: 84px;
    margin: 0 auto;
  }
  .moon,
  .menu,
  .auth {
    grid-column: 1 / 3;
  }
  .moon {
    grid-row: 2;
    justify-self: end;
  }
  .menu {
    grid-row: 3;
    align-self: center;
    justify-self: end;
  }
  .auth {
    grid-row: 4;
    align-self: end;
    justify-self: start;
  }

  .swiperStandard {
    z-index: 3;
    grid-row: 6;
    grid-column: 1/3;
    max-width: 490px;
    display: flex;
    justify-content: space-between;
  }
  .swiperStandard .swiper-slide img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  .title {
    z-index: 3;
    margin-top: 20px;
    font-size: 20px;
    grid-column: 1/3;
    grid-row: 7;
    justify-self: start;
  }

  .details {
    z-index: 3;
    grid-column: 1/3;
    grid-row: 8;
    font-size: 16px;
    justify-self: start;
  }
  .arrows {
    z-index: 3;
    grid-row: 9;
    grid-column: 1/3;
    justify-self: center;
    margin-top: 200px;
  }
}

@media screen and (min-width: 1221px) {
  .phone {
    display: none;
  }
  .home_container {
    display: grid;
    width: 100%;
    max-width: 1640px;
    min-width: 1210px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 40px 80px 20px;
    grid-column-gap: 50px;
  }

  .auth {
    grid-column: 2 / 5;
    justify-self: start;
  }

  .menu {
    grid-column: 4 / 5;
    justify-self: end;
  }

  .moon {
    grid-column: 5 / 6;
    justify-self: center;
  }
  .auth,
  .menu,
  .moon {
    grid-row: 2;
    color: #c9c9c9;
    align-self: center;
  }

  .swiperStandard {
    z-index: 3;
    grid-row: 4;
    grid-column: 2;
    max-width: 490px;
    display: flex;
    justify-content: space-between;
  }
  .swiperStandard .swiper-slide img {
    display: block;
    width: 100%;
    object-fit: cover;
  }

  .common-styles {
    margin-top: 30px;
    grid-row: 5;
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
    justify-self: start;
  }

  .title {
    z-index: 3;
    grid-column: 2;
    margin-top: 20px;
    grid-row: 5;
    justify-self: start;
  }

  .details {
    z-index: 3;
    margin-top: 20px;
    grid-column: 3 / 5;
    grid-row: 5;
    justify-self: left;
  }
  .arrows {
    z-index: 3;
    grid-row: 6;
    grid-column: 2;
    align-self: start;
    justify-self: start;
  }
}

@media screen and (min-width: 1521px) {
  .home_container {
    grid-template-rows: 50px 100px 40px;
  }
  .common-styles {
    font-size: 22px;
  }
}
</style>
