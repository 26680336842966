<template>
  <div class="home_container">
    <moon-btn class="moon" />
    <menu-btn class="menu" />
    <author-btn class="auth" />
    <div class="contact">
      <p class="archivo-medium list secondary m darkEmail">
        {{ u_store.contact.email }}<br />
      </p>
      <a
        :href="u_store.contact.instagram_link"
        style="text-decoration: none"
        class="archivo-it pointer darkLink"
        >instagram</a
      >
    </div>
  </div>
</template>

<script setup lang="ts">
/* imports */
// vue
// libraries
// store
import { uiStore } from '@/store/uiStore'
// components
import MenuBtn from '@/components/MenuBtn.vue'
import MoonBtn from '@/components/MoonBtn.vue'
import AuthorBtn from '@/components/AuthorBtn.vue'

/* script */

const u_store = uiStore()

</script>

<style scoped>
div.home_container {
  margin: 0 auto;
}
a {
  color: #777777;
}
a:hover {
  color: #303030;
}

.darkEmail {
  color: #777777;
}
.darkLink {
  color: #777777;
}
.darkLink:hover {
  color: #000000;
}
@media screen and (max-width: 492px) {
  .home_container {
    display: grid;
    grid-template-columns: 165px 165px;
    grid-template-rows: 15px 30px 15px;
    row-gap: 10px;
    width: 330px;
  }
  .moon,
  .menu {
    grid-row: 2;
  }
  .moon {
    grid-column: 1 / 2;
    justify-self: start;
  }
  .menu {
    grid-column: 2 / 3;
    justify-self: end;
  }
  .auth {
    grid-row: 3;
    grid-column: 1 / 3;
    align-self: start;
  }
  .contact {
    grid-column: 1 / 3;
    grid-row: 4;
    justify-self: start;
    margin-top: 46px;
    line-height: 2.6;
    z-index: 3;
  }
  a {
    font-size: 22px;
  }
}

@media screen and (min-width: 493px) and (max-width: 1220px) {
  /*     grid-template-rows: 15px 30px 15px; */
  .home_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 28px 42px 50px 48px 28px;
    /* grid-template-rows: 15px 30px 15px; */
    width: 100%;
    max-width: 490px;
    min-width: 490px;
    column-gap: 10px;
    margin: 0 auto;
  }
  .moon,
  .menu,
  .auth {
    grid-column: 1 / 3;
  }
  .moon {
    grid-row: 2;
    justify-self: end;
  }

  .menu {
    grid-row: 3;
    align-self: center;
    justify-self: end;
  }

  .auth {
    grid-row: 4;
    align-self: end;
    justify-self: start;
  }

  /* .contact {
    font-size: 22px;
    grid-column: 1 / 3;
    justify-self: start;
  } */

  .contact {
    grid-column: 1 / 3;
    grid-row: 4;
    justify-self: start;
    margin-top: 66px;
    line-height: 2.1;
    font-size: 22px;
    z-index: 3;
  }
  a {
    font-size: 22px;
  }
}

@media screen and (min-width: 1221px) {
  .home_container {
    display: grid;
    width: 100%;
    max-width: 1640px;
    min-width: 1210px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 40px 80px 20px;
    grid-column-gap: 50px;
  }
  .auth {
    grid-column: 2 / 5;
    justify-self: start;
  }
  .menu {
    grid-column: 4 / 5;
    justify-self: end;
  }
  .moon {
    grid-column: 5 / 6;
    justify-self: center;
  }
  .auth,
  .menu,
  .moon {
    grid-row: 2;
    color: #c9c9c9;
    align-self: center;
  }
  .contact {
    z-index: 3;
    grid-column: 2 / 5;
    grid-row: 6;
    line-height: 1.9;
    font-size: 20px;
    justify-self: start;
  }
  /* .contact {
    grid-row: 5;
    grid-column: 2 / 5;
    justify-self: start;
    line-height: 1.9;
    font-size: 20px;
    z-index: 3;
  } */
}
@media screen and (min-width: 1521px) {
  .home_container {
    grid-template-rows: 50px 100px;
  }
  .contact {
    margin-top: 0px;
    font-size: 22px;
    z-index: 3;
    line-height: 2.3;
  }
}
</style>
