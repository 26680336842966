<template>
  <div class="home_container">
    <moon-btn class="moon" />
    <menu-btn class="menu" />
    <author-btn class="auth" />

    <div class="content">
      <ul class="p m">
        <li
          v-for="(book, index) in sortBooksByYear(u_store.books)"
          :key="index"
          class="secondary link"
          :class="u_store.darkMode ? 'darkDescription darkOptions' : 'light'"
        >
          <div class="flex m-b-md">
            <span
              class="m-r-md archivo-it pointer"
              style="white-space: nowrap"
              >{{ book.year }}</span
            >
            <p
              class="archivo-medium pre-line p m pointer"
              @click="
                () => {
                  if (checkAnchor(book.anchor))
                    $router.push({
                      name: 'AlbumPage',
                      params: { anchor: book.anchor },
                    })
                }
              "
            >
              {{ book[`name_${u_store.lang}`] }}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
/* imports */
// vue
// import { onBeforeMount } from 'vue'
// libraries
// store
import { uiStore } from '@/store/uiStore'
// components
import MenuBtn from '@/components/MenuBtn.vue'
import MoonBtn from '@/components/MoonBtn.vue'
import AuthorBtn from '@/components/AuthorBtn.vue'
import { Book } from '@/types'

/* script */
const u_store = uiStore()
// onBeforeMount(async () => {
//   await u_store.fetchBooks()
// })
function sortBooksByYear(projectsArray: Book[]) {
  return projectsArray.sort((a, b) => b.year - a.year)
}
function checkAnchor(anchor: string) {
  console.log(anchor)
  return u_store.albums.some((ex) => ex.anchor === anchor)
}
</script>

<style scoped>
div.home_container {
  margin: 0 auto;
}
ul {
  list-style-type: none;
}

.link:hover {
  color: #303030;
}

.selected {
  color: #777777;
}

.pre-line {
  white-space: pre-line;
}
.article {
  color: #8b8b8b;
}

.options:hover {
  color: #303030;
}
.darkOptions {
  color: #777777;
}
.darkOptions:hover {
  color: #000000;
}
.darkOptions.selected {
  color: #000000;
}

.darkDescription {
  color: #a5a5a5;
}
@media screen and (max-width: 492px) {
  .home_container {
    display: grid;
    grid-template-columns: 165px 165px;
    grid-template-rows: 15px 30px 15px;
    row-gap: 10px;
    width: 330px;
  }
  .moon,
  .menu {
    grid-row: 2;
  }
  .moon {
    grid-column: 1 / 2;
    justify-self: start;
  }
  .menu {
    grid-column: 2 / 3;
    justify-self: end;
  }
  .auth {
    grid-row: 3;
    grid-column: 1 / 3;
    align-self: start;
  }
  .content {
    z-index: 3;
    grid-column: 1 / 3;
    grid-row: 6;
    justify-self: start;
    line-height: 1.3;
  }
}

@media screen and (min-width: 493px) and (max-width: 1220px) {
  .home_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 28px 42px 50px 48px 28px;
    width: 100%;
    max-width: 490px;
    min-width: 490px;
    column-gap: 84px;
    margin: 0 auto;
  }
  .moon,
  .menu,
  .auth {
    grid-column: 1 / 3;
  }
  .moon {
    grid-row: 2;
    justify-self: end;
  }
  .menu {
    grid-row: 3;
    align-self: center;
    justify-self: end;
  }
  .auth {
    grid-row: 4;
    align-self: end;
    justify-self: start;
  }
  .content {
    z-index: 3;
    grid-column: 1 / 3;
    grid-row: 6;
    justify-self: start;
  }
}

@media screen and (min-width: 1221px) {
  .home_container {
    display: grid;
    width: 100%;
    max-width: 1640px;
    min-width: 1210px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 40px 80px 20px;
    grid-column-gap: 50px;
  }

  .auth {
    grid-column: 2 / 5;
    justify-self: start;
  }

  .menu {
    grid-column: 4 / 5;
    justify-self: end;
  }

  .moon {
    grid-column: 5 / 6;
    justify-self: center;
  }
  .auth,
  .menu,
  .moon {
    grid-row: 2;
    color: #c9c9c9;
    align-self: center;
  }
  .content {
    z-index: 3;
    grid-column: 2 / 5;
    grid-row: 5;
    justify-self: start;
  }
  li {
    font-size: 20px;
    margin-bottom: 18px;
  }
}
@media screen and (min-width: 1521px) {
  .home_container {
    grid-template-rows: 50px 100px 40px;
  }
  .content {
    z-index: 3;
  }
  li {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
</style>
