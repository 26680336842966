<template>
  <div class="home_container">
    <moon-btn class="moon" />
    <menu-btn class="menu" />
    <author-btn class="auth" />

    <div class="content">
      <p
        :class="[
          'pointer',
          'archivo-bold',
          'options',
          'archivo-bold',
          'm',
          u_store.darkMode ? 'darkOptions' : 'light',
          u_store.exhibitionsOptions.individual ? 'selected' : '',
        ]"
        @click="setIndividual()"
      >
        {{ u_store.sysT[u_store.lang].individual }}
      </p>
      <p
        :class="[
          'pointer',
          'archivo-bold',
          'options',
          'archivo-bold',
          'm',
          u_store.darkMode ? 'darkOptions' : 'light',
          u_store.exhibitionsOptions.collective ? 'selected' : '',
        ]"
        @click="setCollective()"
      >
        {{ u_store.sysT[u_store.lang].collective }}
      </p>
      <p
        :class="[
          'pointer',
          'archivo-bold',
          'options',
          'archivo-bold',
          'm',
          u_store.darkMode ? 'darkOptions' : 'light',
          u_store.exhibitionsOptions.upcoming ? 'selected' : '',
        ]"
        @click="setUpcoming()"
      >
        {{ u_store.sysT[u_store.lang].upcoming }}
      </p>

      <ul class="p m-t-xl">
        <li
          v-for="(ex, index) in sortExhibitionsByYear(u_store.exhibitions)"
          :key="index"
          class="secondary"
          :class="[
            u_store.darkMode ? 'darkDescription darkOptions' : 'light',
            checkAnchor(ex.anchor) ? 'link' : '',
          ]"
        >
          <div
            v-if="
              (u_store.exhibitionsOptions.individual && ex.individual) ||
              (u_store.exhibitionsOptions.collective && ex.collective) ||
              (u_store.exhibitionsOptions.upcoming && ex.upcoming)
            "
            class="flex m-b-md"
          >
            <span
              class="m-r-md archivo-it pointer"
              style="white-space: nowrap"
              >{{ ex.year }}</span
            >
            <p
              class="archivo-medium pre-line p m pointer"
              @click="
                () => {
                  if (checkAnchor(ex.anchor))
                    $router.push({
                      name: 'AlbumPage',
                      params: { anchor: ex.anchor },
                    })
                }
              "
            >
              {{
                ex[`name_${u_store.lang}`] +
                `${checkAnchor(ex.anchor) ? ' →' : ''}`
              }}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
// libraries
import { uiStore } from '@/store/uiStore'
// import { onBeforeMount } from 'vue'
// components
import MenuBtn from '@/components/MenuBtn.vue'
import MoonBtn from '@/components/MoonBtn.vue'
import AuthorBtn from '@/components/AuthorBtn.vue'
import { Exhibition } from '@/types'
// import { ref } from 'vue'
const u_store = uiStore()
function setCollective() {
  u_store.exhibitionsOptions.collective = true
  u_store.exhibitionsOptions.individual = false
  u_store.exhibitionsOptions.upcoming = false
}
function setIndividual() {
  u_store.exhibitionsOptions.collective = false
  u_store.exhibitionsOptions.individual = true
  u_store.exhibitionsOptions.upcoming = false
}

function setUpcoming() {
  u_store.exhibitionsOptions.collective = false
  u_store.exhibitionsOptions.individual = false
  u_store.exhibitionsOptions.upcoming = true
}

// chceck if anchor exist in u_store.albums

function checkAnchor(anchor: string) {
  // console.log(anchor)
  return u_store.albums.some((ex) => ex.anchor === anchor)
}

function sortExhibitionsByYear(exhibitionsArray: Exhibition[]) {
  
  // filterExhibitionsByType(exhibitionsArray)
  return exhibitionsArray.sort(
    (a, b) => parseInt(b.year, 10) - parseInt(a.year, 10),
  )
}

// function filterExhibitionsByType(exhibitionsArray: Exhibition[]) {
  
//   console.log('individuya;', u_store.exhibitionsOptions.individual, 'collective', u_store.exhibitionsOptions.collective, 'upocomeing', u_store.exhibitionsOptions.upcoming);


//   const exhibitions = exhibitionsArray.filter(
//   (ex) =>
//     ex.individual === u_store.exhibitionsOptions.individual &&
//     ex.collective === u_store.exhibitionsOptions.collective &&
//     ex.upcoming === u_store.exhibitionsOptions.upcoming
// );
//   console.log(exhibitions);

//   // return exhibitions
// }

</script>

<style scoped>
div.home_container {
  margin: 0 auto;
}
ul {
  list-style-type: none;
}
.link:hover {
  color: #303030;
}

.selected {
  color: #777777;
}

.pre-line {
  white-space: pre-line;
}
.article {
  color: #8b8b8b;
}

.options:hover {
  color: #303030;
}
.darkOptions {
  color: #777777;
}
.link.darkOptions:hover {
  color: #000000;
}
.darkOptions.selected {
  color: #000000;
}
.link.options:hover {
  color: #303030;
}

.link.darkOptions:hover {
  color: #000000;
}

.darkDescription {
  color: #a5a5a5;
}
@media screen and (max-width: 492px) {
  .home_container {
    display: grid;
    grid-template-columns: 165px 165px;
    grid-template-rows: 15px 30px 15px;
    row-gap: 10px;
    width: 330px;
  }
  .moon,
  .menu {
    grid-row: 2;
  }
  .moon {
    grid-column: 1 / 2;
    justify-self: start;
  }
  .menu {
    grid-column: 2 / 3;
    justify-self: end;
  }
  .auth {
    grid-row: 3;
    grid-column: 1 / 3;
    align-self: start;
  }
  .content {
    margin-top: 20px;
    line-height: 1.3;
    z-index: 3;
    grid-column: 1 / 3;
    grid-row: 6;
    justify-self: start;
  }
  .options {
    font-size: 19px;
    margin-bottom: 5px;
    line-height: 1.1;
  }
}

@media screen and (min-width: 493px) and (max-width: 1220px) {
  .home_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 28px 42px 50px 48px 28px;
    width: 100%;
    max-width: 490px;
    min-width: 490px;
    column-gap: 84px;
    margin: 0 auto;
  }
  .moon,
  .menu,
  .auth {
    grid-column: 1 / 3;
  }
  .moon {
    grid-row: 2;
    justify-self: end;
  }

  .menu {
    grid-row: 3;
    align-self: center;
    justify-self: end;
  }

  .auth {
    grid-row: 4;
    align-self: end;
    justify-self: start;
  }
  .content {
    z-index: 3;
    grid-column: 1 / 3;
    grid-row: 6;
    justify-self: start;
  }
  .options {
    font-size: 19px;
    margin-bottom: 5px;
    line-height: 1.1;
  }
}

@media screen and (min-width: 1221px) {
  .home_container {
    display: grid;
    width: 100%;
    max-width: 1640px;
    min-width: 1210px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 40px 80px 20px;
    grid-column-gap: 50px;
  }

  .auth {
    grid-column: 2 / 5;
    justify-self: start;
  }

  .menu {
    grid-column: 4 / 5;
    justify-self: end;
  }

  .moon {
    grid-column: 5 / 6;
    justify-self: center;
  }
  .auth,
  .menu,
  .moon {
    grid-row: 2;
    color: #c9c9c9;
    align-self: center;
  }

  .content {
    z-index: 3;
    grid-column: 2 / 5;
    grid-row: 5;
    justify-self: start;
  }

  .options {
    font-size: 19px;
    margin-bottom: 5px;
    line-height: 1.2;
  }
  li {
    font-size: 20px;
    margin-bottom: 18px;
  }
}
@media screen and (min-width: 1521px) {
  .home_container {
    grid-template-rows: 50px 100px 40px;
  }
  li {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
</style>
