<template>
  <div class="home_container">
    <moon-btn class="moon" />
    <menu-btn class="menu" location="menu" />
    <author-btn class="auth" />
    <!--       @click="router.push({ name: el.link })" -->
    <!-- <ul class="pointer menu_list" style="z-index: 1;"> -->
    <p
      v-for="(el, index) in menuOpt"
      @click="router.push({ name: el.link })"
      @touchend.prevent="unBlurThenGo(el.link)"
      :key="index"
      class="primary menu-item pointer"
      :class="[
        u_store.darkMode ? 'darkOptions' : 'light',
        { blur: u_store.isBlurred },
        `index${index}`,
      ]"
    >
      {{ u_store.sysT[u_store.lang][el.title].toUpperCase() }}
    </p>
    <!-- </ul> -->
  </div>
</template>

<script setup lang="ts">
/* imports */
// vue
import { useRouter } from 'vue-router'
// libraries
import { uiStore } from '@/store/uiStore'
import { ref } from 'vue'

// components
import MenuBtn from '@/components/MenuBtn.vue'
import MoonBtn from '@/components/MoonBtn.vue'
import AuthorBtn from '@/components/AuthorBtn.vue'

/* script */
const router = useRouter()
const u_store = uiStore()
const menuOpt = ref([
  {
    title: 'about',
    link: 'about_me',
  },
  {
    title: 'projects',
    link: 'projects',
  },
  {
    title: 'books',
    link: 'books',
  },
  {
    title: 'exhibitions',
    link: 'exhibitions',
  },
  {
    title: 'shop',
    link: 'shop',
  },
  {
    title: 'contact',
    link: 'contact',
  },
])
function unBlurThenGo(path: string) {
  if (u_store.mask === true) return
  router.push({
    name: path,
  })
}
</script>

<style scoped>
div.home_container {
  margin: 0 auto;
}
/* .menu_list {
  list-style: none;
  text-decoration: none;
  padding: 0;
} */
@media screen and (max-width: 492px) {
  .home_container {
    display: grid;
    grid-template-columns: 165px 165px;
    grid-template-rows: 15px 30px 15px;
    row-gap: 10px;
    width: 330px;
  }
  .moon,
  .menu {
    grid-row: 2;
  }
  .moon {
    grid-column: 1 / 2;
    justify-self: start;
  }
  .menu {
    grid-column: 2 / 3;
    justify-self: end;
  }
  .auth {
    grid-row: 3;
    grid-column: 1 / 3;
    align-self: start;
  }

  .menu-item {
    margin: 0px 0px 18px;
    padding: 0;
  }
  .menu-item:hover {
    z-index: 4;
  }
  p.menu-item {
    grid-column: 1/3;
    justify-self: left;
    font-size: 45px;
    line-height: 0.9;
  }
  .index0 {
    margin-top: 30px;
    grid-row: 5;
  }
  .index1 {
    grid-row: 6;
  }
  .index2 {
    grid-row: 7;
  }
  .index3 {
    grid-row: 8;
  }
  .index4 {
    grid-row: 9;
  }
  .index5 {
    grid-row: 10;
  }
}

@media screen and (min-width: 493px) and (max-width: 1220px) {
  .home_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 28px 42px 50px 48px;
    width: 100%;
    max-width: 490px;
    min-width: 490px;
    column-gap: 84px;
    margin: 0 auto;
  }
  .moon,
  .menu,
  .auth {
    grid-column: 1 / 3;
  }
  .moon {
    grid-row: 2;
    justify-self: end;
  }

  .menu {
    grid-row: 3;
    align-self: center;
    justify-self: end;
  }

  .auth {
    grid-row: 4;
    align-self: end;
    justify-self: start;
  }

  .menu-item {
    margin: 0px 0px 18px;
    padding: 0;
  }
  .menu-item:hover {
    z-index: 4;
  }
  p.menu-item {
    grid-column: 1/3;
    justify-self: left;
    font-size: 50px;
    line-height: 1.5;
  }
  .index0 {
    margin-top: 30px;
    grid-row: 5;
  }
  .index1 {
    grid-row: 6;
  }
  .index2 {
    grid-row: 7;
  }
  .index3 {
    grid-row: 8;
  }
  .index4 {
    grid-row: 9;
  }
  .index5 {
    grid-row: 10;
  }
}

@media screen and (min-width: 1221px) {
  .home_container {
    display: grid;
    width: 100%;
    max-width: 1640px;
    min-width: 1210px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 40px 80px 20px;
    grid-column-gap: 50px;
  }

  .auth {
    grid-column: 2 / 5;
    justify-self: start;
  }

  .menu {
    grid-column: 4 / 5;
    justify-self: end;
  }

  .moon {
    grid-column: 5 / 6;
    justify-self: center;
  }
  .auth,
  .menu,
  .moon {
    grid-row: 2;
    color: #c9c9c9;
    align-self: center;
  }

  .menu-item {
    margin: 0px 0px 25px;
    padding: 0;
  }
  .menu-item:hover {
    z-index: 4;
  }
  p.menu-item {
    grid-column: 2 / 5;
    justify-self: left;
    font-size: 40px;
    line-height: 1;
  }
  .index0 {
    grid-row: 5;
  }
  .index1 {
    grid-row: 6;
  }
  .index2 {
    grid-row: 7;
  }
  .index3 {
    grid-row: 8;
  }
  .index4 {
    grid-row: 9;
  }
  .index5 {
    grid-row: 10;
  }
}
@media screen and (min-width: 1521px) {
  .home_container {
    grid-template-rows: 50px 100px 40px;
  }

  .menu-item {
    margin: 0px 0px 40px;
    padding: 0;
  }
  .menu-item:hover {
    z-index: 4;
  }
  p.menu-item {
    grid-column: 2 / 5;
    justify-self: left;
    font-size: 50px;
    line-height: 1;
  }
  .index0 {
    grid-row: 5;
  }
  .index1 {
    grid-row: 6;
  }
  .index2 {
    grid-row: 7;
  }
  .index3 {
    grid-row: 8;
  }
  .index4 {
    grid-row: 9;
  }
  .index5 {
    grid-row: 10;
  }
}
</style>
