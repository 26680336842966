<template>
  <p
    :class="[
      'text-h1',
      'pointer',
      { blur: u_store.isBlurred },
      u_store.darkMode ? 'darkOptions' : 'light',
    ]"
    @click="$router.push({ name: 'HomeView' })"
    @touchstart.prevent="unBlurThenGo()"
  >
    {{ u_store.authorName.toUpperCase() }}
  </p>
</template>
<script setup lang="ts">
// store
import { uiStore } from '@/store/uiStore'
import { useRouter } from 'vue-router'
const u_store = uiStore()
const router = useRouter()
function unBlurThenGo() {
  if (u_store.mask === true) return
  router.push({
    name: 'HomeView',
  })
}
</script>
<style scoped>
.darkOptions {
  /* color transaprent */
  color: #777777fa !important;
}

@media screen and (max-width: 492px) {
  .text-h1 {
    font-size: 24px;
    margin: 0;
    padding: 0;
  }
}
@media screen and (min-width: 493px) and (max-width: 1220px) {
  .text-h1 {
    font-size: 35.4px;
    margin: 0;
  }
}
@media screen and (min-width: 1221px) {
  .text-h1 {
    font-size: 35.5px;
    margin: 0;
  }
}
@media screen and (min-width: 1521px) {
  .text-h1 {
    font-size: 40px;
    margin: 0;
  }
}
</style>
