<template>
  <div class="home_container">
    <moon-btn class="moon" />
    <menu-btn class="menu" />
    <author-btn class="auth" />
    <figure
      v-for="(album, index) in u_store.homePageAlbums.slice(0, 6)"
      :key="index"
      :class="['id' + album.id, { blur: u_store.isBlurred }]"
      @click="
        () => {
          if (checkAnchor(album.anchor))
            $router.push({
              name: 'AlbumPage',
              params: { anchor: album.anchor },
            })
        }
      "
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend.prevent="handleTouchEnd(album.anchor)"
    >
      <img
        :src="album.url"
        style="aspect-ratio: 1 / 1; object-fit: cover; width: 100%;"
      />

      <figcaption
        class="text-figcaption"
        :class="u_store.darkMode ? 'darkOptions' : 'light'"
        style="min-height: 40px"
      >
        {{
          album[`name_${u_store.lang}`].length > maxLength
            ? `${album[`name_${u_store.lang}`].slice(0, maxLength)} ...`
            : album[`name_${u_store.lang}`]
        }}
      </figcaption>
    </figure>
  </div>
</template>

<script setup lang="ts">
/* imports */
// vue
import { useRouter } from 'vue-router'

// libraries
// store
import { uiStore } from '@/store/uiStore'
// components
import MenuBtn from '@/components/MenuBtn.vue'
import MoonBtn from '@/components/MoonBtn.vue'
import AuthorBtn from '@/components/AuthorBtn.vue'
import { ref } from 'vue'

/* script */
const u_store = uiStore()
const router = useRouter()
const touchStartY = ref(0)
const isScrolling = ref(false)
const maxLength = 32

function handleTouchStart(event: TouchEvent) {
  touchStartY.value = event.touches[0].clientY
  isScrolling.value = false
}
function handleTouchMove() {
  isScrolling.value = true
}
function handleTouchEnd(anchor: string) {
  if (!isScrolling.value && u_store.mask === false) {
    goToAlbum(anchor)
  }
}
function goToAlbum(anchor: string) {
  // console.log('go to album')
  // console.log('isPhoneBlur', u_store.isPhoneBlurred)
  // console.log('mask', u_store.mask)
  if (u_store.mask === true) return
  router.push({
    name: 'AlbumPage',
    params: { anchor: anchor },
  })
}
function checkAnchor(anchor: string) {
  // console.log(anchor)
  return u_store.albums.some((ex) => ex.anchor === anchor)
}
</script>

<style scoped>
div.home_container {
  margin: 0 auto;
}

@media screen and (max-width: 492px) {
  .home_container {
    display: grid;
    grid-template-columns: 165px 165px;
    grid-template-rows: 15px 30px 30px;
    row-gap: 10px;
    width: 330px;
  }
  .moon,
  .menu {
    grid-row: 2;
  }
  .moon {
    grid-column: 1 / 2;
    justify-self: start;
  }
  .menu {
    grid-column: 2 / 3;
    justify-self: end;
  }
  .auth {
    grid-row: 3;
    grid-column: 1 / 3;
    align-self: start;
  }

  figure.id1 {
    margin: 5px 0px 0px 0px;
    grid-column: 1/3;
    grid-row: 4;
  }
  figure.id2 {
    margin: 0px;
    grid-column: 1/3;
    grid-row: 5;
  }
  figure.id3 {
    margin: 0px;
    grid-column: 1/3;
    grid-row: 6;
  }
  figure.id4 {
    margin: 0px;
    grid-column: 1/3;
    grid-row: 7;
  }
  figure.id5 {
    margin: 0px 0px 0px 0px;
    grid-column: 1/3;
    grid-row: 8;
  }
  figure.id6 {
    margin: 0px 0px 0px 0px;
    grid-column: 1/3;
    grid-row: 9;
  }

  figure {
    height: 104px;
  }

  img {
    height: 75px;
    border-radius: 3px;
  }

  .text-figcaption {
    grid-column: 1/3;
    margin-top: 3px;
    font-size: 14px;
  }
}

@media screen and (min-width: 493px) and (max-width: 1220px) {
  .home_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 28px 42px 50px 48px 28px;
    width: 100%;
    max-width: 490px;
    min-width: 490px;
    column-gap: 84px;
    margin: 0 auto;
  }
  /* .home_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 30px 35px 50px 40px 33px;
    width: 100%;
    max-width: 490px;
    min-width: 490px;
    column-gap: 84px;
    margin: 0 auto;
  } */
  .moon,
  .menu,
  .auth {
    grid-column: 1 / 3;
  }
  .moon {
    grid-row: 2;
    justify-self: end;
  }

  .menu {
    grid-row: 3;
    align-self: center;
    justify-self: end;
  }

  .auth {
    grid-row: 4;
    align-self: end;
    justify-self: start;
  }

  figure.id1 {
    grid-row: 6;
    grid-column: 1 / 2;
  }
  figure.id2 {
    grid-row: 7;
    grid-column: 1 / 2;
  }
  figure.id3 {
    grid-row: 8;
    grid-column: 1 / 2;
  }

  figure.id4 {
    grid-row: 6;
    grid-column: 2 / 3;
  }

  figure.id5 {
    grid-row: 7;
    grid-column: 2 / 3;
  }

  figure.id6 {
    grid-row: 8;
    grid-column: 2 / 3;
  }

  img {
    height: 100%;
    width: 100%;
    max-width: 200px;
    max-height: 200px;
  }

  figure {
    margin: 0;
    grid-column: 1/4;
    height: 269px;
  }

  .text-figcaption {
    font-size: 16px;
  }
}
@media screen and (min-width: 1221px) {
  .home_container {
    display: grid;
    width: 100%;
    max-width: 1640px;
    min-width: 1210px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 40px 80px 20px;
    grid-column-gap: 50px;
  }

  .auth {
    grid-column: 2 / 5;
    justify-self: start;
  }

  .menu {
    grid-column: 4 / 5;
    justify-self: end;
  }

  .moon {
    grid-column: 5 / 6;
    justify-self: center;
  }
  .auth,
  .menu,
  .moon {
    grid-row: 2;
    color: #c9c9c9;
    align-self: center;
  }

  figure.id1 {
    grid-column: 2;
    grid-row: 4;
  }
  figure.id2 {
    grid-column: 3;
    grid-row: 4;
  }
  figure.id3 {
    grid-column: 4;
    grid-row: 4;
  }

  figure.id4 {
    grid-column: 2;
    grid-row: 5;
  }
  figure.id5 {
    grid-column: 3;
    grid-row: 5;
  }
  figure.id6 {
    grid-column: 4;
    grid-row: 5;
  }
  figure {
    margin: 0 auto 20px;
  }

  img {
    width: 100%;
    max-width: 288px;
    max-height: 288px;
  }

  .text-figcaption {
    margin-top: 10px;
    font-size: 16px;
  }
}
@media screen and (min-width: 1521px) {
  .home_container {
    display: grid;
    width: 100%;
    max-width: 1640px;
    min-width: 1210px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 50px 100px 40px;
    grid-column-gap: 50px;
  }
  .text-figcaption {
    margin-top: 10px;
    font-size: 22px;
  }
  figure {
    margin: 0 auto 50px;
  }
}
</style>
