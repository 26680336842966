import HomePageVue from '@/pages/HomePage.vue'
import AlbumPage from '@/pages/AlbumPage.vue'
import MenuPage from '@/pages/MenuPage.vue'
// import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import { createRouter, createMemoryHistory, RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import AboutMePage from '@/pages/AboutMePage.vue'
import ProjectPage from '@/pages/ProjectPage.vue'
import ContactPage from '@/pages/ContactPage.vue'
import ShopPage from '@/pages/ShopPage.vue'
import BooksPage from '@/pages/BooksPage.vue'
import ExhibitionsPage from '@/pages/ExhibitionsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomeView',
    component: HomePageVue,
  },
  {
    path: '/album/:anchor',
    name: 'AlbumPage',
    component: AlbumPage,
  },
  {
    path: '/menu',
    name: 'MenuPage',
    component: MenuPage,
  },
  {
    path: '/about_me',
    name: 'about_me',
    component: AboutMePage,
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectPage,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage,
  },
  {
    path: '/shop',
    name: 'shop',
    component: ShopPage,
  },
  {
    path: '/books',
    name: 'books',
    component: BooksPage,
  },
  {
    path: '/exhibitions',
    name: 'exhibitions',
    component: ExhibitionsPage,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

// const router = createRouter({
//   history: createWebHistory(),
//   routes
// })

export default router
