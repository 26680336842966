// mainStore.ts
// import HomePage from '@/pages/HomePage.vue'
import { defineStore } from 'pinia'
import {
  Album,
  Project,
  HomePageAlbum,
  Book,
  Exhibition,
  Contact,
  Shop,
  Statement,
  Education,
  Award,
} from '@/types'
type Lang = Record<'pl' | 'en', Record<string, string>>
export const uiStore = defineStore('storeId', {
  // Stan
  state: (): {
    contentfulClient: any
    homePageAlbums: HomePageAlbum[]
    projects: Project[]
    exhibitions: Exhibition[]
    statement: Statement
    contact: Contact
    counter: number
    darkMode: boolean
    isBlurred: boolean
    lang: 'pl' | 'en'
    langSwitch: ['pl', 'en']
    authorName: string
    albums: Album[]
    books: Book[]
    education: Education[]
    awards: Award[]
    shop: Shop[]
    files: string[]
    sysT: Lang
    lastFilesSource: string
    isPhoneBlurred: boolean
    timeoutId: number | undefined
    isLoaded: boolean
    interviews: {
      link: string
      name_pl: string
      name_en: string
    }[]
    exhibitionsOptions: {
      individual: boolean
      collective: boolean
      upcoming: boolean
    }
    aboutMeOptions: {
      current: 'statement' | 'education' | 'awards' | 'interviews'
    }
    mask: boolean
  } => ({
    contentfulClient: undefined,
    homePageAlbums: [],
    exhibitions: [],
    contact: {
      email: '',
      instagram_link: '',
    },
    statement: {
      description_pl: [],
      description_en: [],
    },
    education: [],
    awards: [],
    isLoaded: false,
    lastFilesSource: '',
    counter: 0,
    darkMode: false,
    isBlurred: true,
    isPhoneBlurred: true,
    timeoutId: undefined,
    lang: 'pl',
    langSwitch: ['pl', 'en'],
    authorName: 'aleksandra żalińska',
    albums: [],
    books: [],
    projects: [],
    shop: [],
    interviews: [],
    files: [],
    sysT: {
      pl: {
        home: 'Strona główna',
        about: 'O mnie',
        books: 'Książki',
        projects: 'Projekty',
        exhibitions: 'Wystawy',
        contact: 'Kontakt',
        individual: 'Wybrane wystawy indywidualne',
        collective: 'Wybrane wystawy grupowe',
        upcoming: 'Nadchodzące wystawy',
        shop: 'Sklep',
        albums: 'Albumy',
        author: 'Aleksandra Żalińska',
        darkMode: 'Tryb ciemny',
        lang: 'Język',
        next: 'Następne',
        previous: 'Poprzednie',
        more: 'więcej',
        less: 'mniej',
      },
      en: {
        home: 'Home',
        about: 'About me',
        books: 'Books',
        projects: 'Projects',
        exhibitions: 'Exhibitions',
        individual: 'Selected individual exhibitions',
        collective: 'Selected collective exhibitions',
        upcoming: 'Upcoming exhibitions',
        contact: 'Contact',
        shop: 'Shop',
        albums: 'Albums',
        author: 'Aleksandra Żalińska',
        darkMode: 'Dark mode',
        lang: 'Language',
        next: 'Next',
        previous: 'Previous',
        more: 'more',
        less: 'less',
      },
    },
    exhibitionsOptions: {
      individual: true,
      collective: false,
      upcoming: false,
    },
    aboutMeOptions: {
      current: 'statement',
    },
    mask: true,
  }),

  // Akcje
  actions: {
    increment() {
      this.counter++
    },
    async fetchData(path: string) {
      const response = await fetch(path)
      const data = await response.json()
      return Object.keys(data).map((key) => ({
        id: key,
        coverPicture: data[key].img[0],
        selectedPicture: data[key].img[0],
        pl: {
          title: data[key].txt.pl.title,
          description: data[key].txt.pl.desc,
        },
        en: {
          title: data[key].txt.en.title,
          description: data[key].txt.en.desc,
        },
        collection: data[key].img,
      }))
    },
  },

  // Gettery
  getters: {
    doubleCount: (state) => state.counter * 2,
  },
})
