<template>
  <div
    class="container archivo-black"
    @touchstart="hideBlur"
    style="display: relative"
  >
    <router-view v-if="u_store.isLoaded" />
    <div class="mask" v-if="u_store.mask"></div>
  </div>
</template>
<script lang="ts" setup>
import { uiStore } from '@/store/uiStore'
import { onBeforeMount, watch, getCurrentInstance } from 'vue'
import {
  AlbumItem,
  HomePageAlbumItem,
  InterviewItem,
  ProjectItem,
  BookItem,
  ExhibitionItem,
  ContactItem,
  ShopItem,
  StatementItem,
  EducationItem,
  AwardsItem,
} from '@/types'
const u_store = uiStore()
const instance = getCurrentInstance()
const contentfulClient =
  instance?.appContext.config.globalProperties.$contentful

// Funkcja pomocnicza do pobierania danych
async function fetchDataFromContentful(contentType: string) {
  try {
    const response = await contentfulClient.getEntries({
      content_type: contentType,
    })
    return response.items
  } catch (error) {
    console.error(
      `Error fetching data from Contentful for ${contentType}:`,
      error,
    )
    return []
  }
}

async function fetchContentfulData() {
  const items = (await fetchDataFromContentful('interviews')) as InterviewItem[]
  // console.log('interview', items)
  u_store.interviews.push(...items.map((el) => el.fields))
}

async function fetchAlbums() {
  const items = (await fetchDataFromContentful('albums')) as AlbumItem[]
  // console.log('albums', items)
  const albums = items.map((item) => ({
    anchor: item.fields.anchor,
    collection: item.fields.collection.map((el) => {
      return {
        url: el.fields.file.url,
        dimensions: {
          width: el.fields.file.details.image.width,
          height: el.fields.file.details.image.height,
        },
      }
    }),
    selectedPicture: item.fields.collection[0]?.fields.file.url,
    name_pl: item.fields.name_pl,
    name_en: item.fields.name_en,
    description_pl: item.fields.description_pl.content.map((paragraph) =>
      paragraph.content.map((textNode) => textNode.value).join('\n'),
    ),

    description_en: item.fields.description_en.content.map((paragraph) =>
      paragraph.content.map((textNode) => textNode.value).join('\n'),
    ),
  }))
  // console.log('albums', albums)
  u_store.albums.push(...albums)
}

async function fetchHomePageAlbums() {
  const items = (await fetchDataFromContentful('home')) as HomePageAlbumItem[]
  // console.log('home', items)
  const homePageAlbums = items.map((el) => ({
    id: el.fields.order,
    anchor: el.fields.anchor,
    url: el.fields.image.fields.file.url,
    name_pl: el.fields.name_pl,
    name_en: el.fields.name_en,
  }))
  // console.log('home', homePageAlbums)
  u_store.homePageAlbums.push(...homePageAlbums)
}

async function fetchProjects() {
  const items = (await fetchDataFromContentful('projects')) as ProjectItem[]
  // console.log('proj', items)
  const projects = items.map((item) => ({
    anchor: item.fields.anchor,
    year: item.fields.year,
    name_pl: item.fields.name_pl,
    name_en: item.fields.name_en,
  }))
  // console.log('proj', projects)
  u_store.projects.push(...projects)
}

async function fetchBooks() {
  const items = (await fetchDataFromContentful('books')) as BookItem[]
  // console.log('books', items)
  const books = items.map((item) => ({
    anchor: item.fields.anchor,
    year: item.fields.year,
    name_pl: item.fields.name_pl,
    name_en: item.fields.name_en,
  }))
  // console.log('books', books)
  u_store.books.push(...books)
}

async function fetchEducation() {
  const items = (await fetchDataFromContentful('education')) as EducationItem[]
  // console.log('edu', items)
  const education = items.map((item) => ({
    year_start: item.fields.year_start,
    year_end: item.fields.year_end,
    name_pl: item.fields.name_pl,
    name_en: item.fields.name_en,
  }))
  // console.log('edu', education)
  u_store.education.push(...education)
}

async function fetchAwards() {
  const items = (await fetchDataFromContentful('awards')) as AwardsItem[]
  // console.log('awards', items)
  const awards = items.map((item) => ({
    year: item.fields.year,
    name_pl: item.fields.name_pl,
    name_en: item.fields.name_en,
  }))
  // console.log('awards', awards)
  u_store.awards.push(...awards)
}

async function fetchExhibitions() {
  const items = (await fetchDataFromContentful(
    'exhibitions',
  )) as ExhibitionItem[]
  // console.log('exhibition', items)
  const exhibitions = items.map((item) => ({
    anchor: item.fields.anchor,
    year: item.fields.year,
    name_pl: item.fields.name_pl,
    name_en: item.fields.name_en,
    collective: item.fields.collective,
    individual: item.fields.individual,
    upcoming: item.fields.upcoming,
  }))
  // console.log('exhibition', exhibitions)
  u_store.exhibitions.push(...exhibitions)
}

async function fetchShop() {
  const items = (await fetchDataFromContentful('shop')) as ShopItem[]
  // console.log('shop', items)
  const shop = items.map((item) => ({
    name_pl: item.fields.name_pl.content.map((paragraph) =>
      paragraph.content.map((textNode) => textNode.value).join('\n'),
    ),

    name_en: item.fields.name_en.content.map((paragraph) =>
      paragraph.content.map((textNode) => textNode.value).join('\n'),
    ),

    description_pl: item.fields.description_pl.content.map((paragraph) =>
      paragraph.content.map((textNode) => textNode.value).join('\n'),
    ),

    description_en: item.fields.description_en.content.map((paragraph) =>
      paragraph.content.map((textNode) => textNode.value).join('\n'),
    ),
    image: item.fields.image.fields.file.url,
  }))
  // console.log('shop', shop)
  u_store.shop.push(...shop)
}

async function fetchContact() {
  const items = (await fetchDataFromContentful('contact')) as ContactItem[]
  // console.log('contact', items)
  u_store.contact.email = items[0].fields.email
  u_store.contact.instagram_link = items[0].fields.instagram
}

async function fetchStatement() {
  const items = (await fetchDataFromContentful('statement')) as StatementItem[]
  // console.log('statement', items)
  const statement = items.map((item) => ({
    description_pl: item.fields.description_pl.content.map((paragraph) =>
      paragraph.content.map((textNode) => textNode.value).join('\n\n'),
    ),

    description_en: item.fields.description_en.content.map((paragraph) =>
      paragraph.content.map((textNode) => textNode.value).join('\n\n'),
    ),
  }))
  // console.log('statement', statement)
  u_store.statement = statement[0]
}

onBeforeMount(async () => {
  await Promise.all([
    fetchHomePageAlbums(),
    fetchContentfulData(),
    fetchAlbums(),
    fetchProjects(),
    fetchBooks(),
    fetchExhibitions(),
    fetchContact(),
    fetchShop(),
    fetchStatement(),
    fetchAwards(),
    fetchEducation(),
  ])
  u_store.isLoaded = true
})

watch(
  () => u_store.darkMode,
  (newVal) => {
    if (newVal === true) {
      document.body.style.backgroundColor = '#3D3D3D'
    } else {
      document.body.style.backgroundColor = '#FFFFFF'
    }
  },
)
function hideBlur() {
  u_store.isBlurred = false
  setTimeout(() => {
    u_store.mask = false
  }, 100)
  // Anuluj poprzedni timeout, jeśli istnieje
  clearTimeout(u_store.timeoutId)
  u_store.timeoutId = setTimeout(() => {
    u_store.isBlurred = true
    u_store.mask = true
  }, 4000)
}
</script>

<style>
#app {
  font-family: 'Archivo Black', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  /* mix-blend-mode: unset; */
}
.darkOptions {
  color: #777777fa;
}
.hide {
  display: none;
}

.moon {
  align-self: center;
  filter: blur(0) !important;
}
.moon-btn {
  position: relative;
  background: url('~@/assets/moon/moon_light_20.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  /* border-radius: 50%; */
  transition: background 0.5s;
}
.moon-btn:hover {
  position: relative;
  background: url('~@/assets/moon/moon_light_back_20.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  /* border-radius: 50%; */
  transition: background 0.5s;
}

.darkOptions.moon-btn {
  position: relative;
  background: url('~@/assets/moon/moon_dark_20.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  /* border-radius: 50%; */
  transition: background 0.5s;
}
.darkOptions.moon-btn:hover {
  position: relative;
  background: url('~@/assets/moon/moon_dark_back_20.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  /* border-radius: 50%; */
  transition: background 0.5s;
}

@media screen and (max-width: 492px) {
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: #cfcfcf00;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    pointer-events: none;
  }
  .moon-btn {
    position: relative;
    background: url('~@/assets/moon/moon_light_29.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 29px;
    /* border-radius: 50%; */
    transition: background 0.5s;
  }
  .moon-btn:hover {
    position: relative;
    background: url('~@/assets/moon/moon_light_back_29.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 29px;
    /* border-radius: 50%; */
    transition: background 0.5s;
  }

  .darkOptions.moon-btn {
    position: relative;
    background: url('~@/assets/moon/moon_dark_29.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 29px;
    /* border-radius: 50%; */
    transition: background 0.5s;
  }
  .darkOptions.moon-btn:hover {
    position: relative;
    background: url('~@/assets/moon/moon_dark_back_29.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 29px;
    /* border-radius: 50%; */
    transition: background 0.5s;
  }
}
@media screen and (min-width: 493px) and (max-width: 1220px) {
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: #cfcfcf00;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    pointer-events: none;
  }
  .moon-btn {
    position: relative;
    background: url('~@/assets/moon/moon_light_40.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px;
    /* border-radius: 50%; */
    transition: background 0.5s;
  }
  .moon-btn:hover {
    position: relative;
    background: url('~@/assets/moon/moon_light_back_40.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px;
    /* border-radius: 50%; */
    transition: background 0.5s;
  }

  .darkOptions.moon-btn {
    position: relative;
    background: url('~@/assets/moon/moon_dark_40.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px;
    /* border-radius: 50%; */
    transition: background 0.5s;
  }
  .darkOptions.moon-btn:hover {
    position: relative;
    background: url('~@/assets/moon/moon_dark_back_40.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px;
    /* border-radius: 50%; */
    transition: background 0.5s;
  }
}
@media screen and (min-width: 1221px) {
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: #cfcfcf00;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    pointer-events: none;
  }
  .moon-btn {
    position: relative;
    background: url('~@/assets/moon/moon_light_70.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px;
    border-radius: 50%;
    transition: background 0.5s;
  }
  .moon-btn:hover {
    position: relative;
    background: url('~@/assets/moon/moon_light_back_70.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px;
    border-radius: 50%;
    transition: background 0.5s;
  }

  .darkOptions.moon-btn {
    position: relative;
    background: url('~@/assets/moon/moon_dark_70.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px;
    border-radius: 50%;
    transition: background 0.5s;
  }
  .darkOptions.moon-btn:hover {
    position: relative;
    background: url('~@/assets/moon/moon_dark_back_70.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px;
    border-radius: 50%;
    transition: background 0.5s;
  }
}

@media screen and (min-width: 1221px) {
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: #cfcfcf00;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    pointer-events: none;
  }
  .moon-btn {
    position: relative;
    background: url('~@/assets/moon/moon_light.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 82px;
    border-radius: 50%;
    transition: background 0.5s;
  }
  .moon-btn:hover {
    position: relative;
    background: url('~@/assets/moon/moon_light_back.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 82px;
    border-radius: 50%;
    transition: background 0.5s;
  }

  .darkOptions.moon-btn {
    position: relative;
    background: url('~@/assets/moon/moon_dark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 82px;
    border-radius: 50%;
    transition: background 0.5s;
  }
  .darkOptions.moon-btn:hover {
    position: relative;
    background: url('~@/assets/moon/moon_dark_back.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 82px;
    border-radius: 50%;
    transition: background 0.5s;
  }
}

figure.blur img {
  opacity: 0.3;
}
/* rem blur on hover */
@media (hover: hover) and (pointer: fine) {
  .blur:hover {
    z-index: 3;
  }
  figure.blur:hover img {
    opacity: 1;
  }
}
.archivo-black {
  font-family: 'Archivo Black';
}
.archivo-bold {
  font-family: 'Archivo', sans-serif;
  font-weight: 700;
}
.archivo-medium {
  font-family: 'Archivo', sans-serif;
  font-weight: 500;
}
.archivo-reg {
  font-family: 'Archivo', sans-serif;
  font-weight: 400;
}
.archivo-it {
  font-family: 'Archivo', sans-serif;
  font-weight: 400;
  font-style: italic;
}
.container {
  color: #c9c9c9;
}
</style>
