<template>
  <div class="home_container">
    <moon-btn class="moon" />
    <menu-btn class="menu" />
    <author-btn class="auth" />

    <div class="content">
      <p
        v-for="(el, index) in options"
        :key="index"
        @click="u_store.aboutMeOptions.current = el"
        :class="[
          'pointer',
          'archivo-bold',
          'options',
          'archivo-bold',
          'm',
          u_store.darkMode ? 'darkOptions' : 'light',
          u_store.aboutMeOptions.current === el ? 'selected' : '',
        ]"
      >
        {{ langModel[u_store.lang][el] }}
      </p>

      <img
        v-if="u_store.aboutMeOptions.current === 'statement'"
        :src="require('@/assets/1.jpg')"
        :alt="'Aleksandra Zalinska'"
        class="img_box pic1"
        height="160"
        width="310"
        style="object-fit: cover"
      />

      <div
        v-if="u_store.aboutMeOptions.current === 'statement'"
        class="content"
      >
        <p
          class="archivo-medium article pre-line"
          v-for="(p, index) in u_store.statement[`description_${u_store.lang}`]"
          :key="index"
        >
          {{ p }}
        </p>
      </div>

      <div v-if="u_store.aboutMeOptions.current === 'education'">
        <ul class="p m-t-xl">
          <li
            v-for="(el, index) in sortEducationByStartYear(u_store.education)"
            :key="index"
            class="flex m-b-md"
          >
            <span
              class="m-r-md archivo-it secondary"
              style="white-space: nowrap"
              >{{ el.year_start }} - {{ el.year_end }}</span
            >
            <p
              class="secondary archivo-medium pre-line p m"
              :class="u_store.darkMode ? 'darkDescription' : 'light'"
            >
              {{ el[`name_${u_store.lang}`] }}
            </p>
          </li>
        </ul>
      </div>

      <div v-if="u_store.aboutMeOptions.current === 'awards'">
        <ul class="p m-t-xl">
          <li
            v-for="(el, index) in u_store.awards"
            :key="index"
            class="flex m-b-md"
          >
            <span
              class="m-r-md archivo-it secondary"
              :class="u_store.darkMode ? 'darkDescription' : 'light'"
              style="white-space: nowrap"
              >{{ el.year }}</span
            >

            <p
              class="secondary archivo-medium pre-line p m"
              :class="u_store.darkMode ? 'darkDescription' : 'light'"
            >
              {{ el[`name_${u_store.lang}`] }}
            </p>
          </li>
        </ul>
      </div>

      <div
        v-if="u_store.aboutMeOptions.current === 'interviews'"
        class="m-t-xl"
      >
        <ul class="p">
          <li
            v-for="(el, index) in u_store.interviews"
            :key="index"
            class="m-b-md secondary link"
          >
            <a
              :href="el.link"
              :class="
                u_store.darkMode ? 'darkDescription darkOptions' : 'light'
              "
              class="flex no-wrap pre-line archivo-medium"
            >
              <span class="archivo-it m-r-md">https://</span>
              <p class="p m">{{ el[`name_${u_store.lang}`] }}</p>
            </a>
          </li>
        </ul>
      </div>

      <!-- <p 
        class="article archivo-medium pre-line p m"
        :class="ui_store.darkMode ? 'darkDescription':'light'"
      >{{ currentContent }}</p> -->
    </div>
  </div>
</template>

<script setup lang="ts">
// libraries
import { uiStore } from '@/store/uiStore'
// components
import MenuBtn from '@/components/MenuBtn.vue'
import MoonBtn from '@/components/MoonBtn.vue'
import AuthorBtn from '@/components/AuthorBtn.vue'
import { Education } from '@/types'
const u_store = uiStore()
const options: ['statement', 'education', 'awards', 'interviews'] = [
  'statement',
  'education',
  'awards',
  'interviews',
]
const langModel: {
  en: {
    statement: string
    education: string
    awards: string
    interviews: string
  }
  pl: {
    statement: string
    education: string
    awards: string
    interviews: string
  }
} = {
  en: {
    statement: 'Statement',
    education: 'Education',
    awards: 'Selected awards',
    interviews: 'Reviews - Interviews - Publications',
  },
  pl: {
    statement: 'Statement',
    education: 'Edukacja',
    awards: 'Wybrane nagrody',
    interviews: 'Recenzje - Wywiady - Publikacje',
  },
}

function sortEducationByStartYear(educationArray: Education[]) {
  return educationArray.sort((a, b) => b.year_start - a.year_start)
}
</script>

<style scoped>
div.home_container {
  margin: 0 auto;
}
img {
  margin-top: 22px;
}
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
ul {
  list-style-type: none;
}
a:hover {
  color: #303030;
}

.selected {
  color: #777777;
}

.pre-line {
  white-space: pre-line;
}
.article {
  color: #8b8b8b;
}

.options:hover {
  color: #303030;
}
.darkOptions {
  color: #777777;
}
/* .darkOptions:hover {
  color: #000000;
} */
.darkOptions.selected {
  color: #000000;
}

.darkDescription {
  color: #a5a5a5;
}

@media screen and (max-width: 492px) {
  .home_container {
    display: grid;
    grid-template-columns: 165px 165px;
    grid-template-rows: 15px 30px 15px;
    row-gap: 10px;
    width: 330px;
  }
  .moon,
  .menu {
    grid-row: 2;
  }
  .moon {
    grid-column: 1 / 2;
    justify-self: start;
  }
  .menu {
    grid-column: 2 / 3;
    justify-self: end;
  }
  .auth {
    grid-row: 3;
    grid-column: 1 / 3;
    align-self: start;
  }

  .content {
    z-index: 3;
    grid-column: 1 / 3;
    grid-row: 4;
    justify-self: start;
    margin-top: 20px;
    line-height: 1.3;
  }
  .options {
    font-size: 19px;
    margin-bottom: 5px;
    line-height: 1.1;
  }

  .article {
    grid-column: 1 / 3;
    justify-self: start;
  }
}

@media screen and (min-width: 493px) and (max-width: 1220px) {
  /* grid-template-rows: 28px 32px 50px 48px 28px; */
  .home_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 28px 42px 50px 48px 28px;
    width: 100%;
    max-width: 490px;
    min-width: 490px;
    column-gap: 84px;
    margin: 0 auto;
  }
  .moon,
  .menu,
  .auth {
    grid-column: 1 / 3;
  }
  .moon {
    grid-row: 2;
    justify-self: end;
  }

  .menu {
    grid-row: 3;
    align-self: center;
    justify-self: end;
  }

  .auth {
    grid-row: 4;
    align-self: end;
    justify-self: start;
  }

  .content {
    z-index: 3;
    grid-column: 1 / 3;
    grid-row: 6;
    justify-self: start;
  }
  .options {
    font-size: 19px;
    margin-bottom: 5px;
    line-height: 1.1;
  }
  .article {
    grid-column: 1 / 3;
    justify-self: start;
  }
}

@media screen and (min-width: 1221px) {
  .home_container {
    display: grid;
    width: 100%;
    max-width: 1640px;
    min-width: 1210px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 40px 80px 20px;
    grid-column-gap: 50px;
  }
  .auth {
    grid-column: 2 / 5;
    justify-self: start;
  }

  .menu {
    grid-column: 4 / 5;
    justify-self: end;
  }

  .moon {
    grid-column: 5 / 6;
    justify-self: center;
  }
  .auth,
  .menu,
  .moon {
    grid-row: 2;
    color: #c9c9c9;
    align-self: center;
  }

  .content {
    z-index: 9;
    grid-column: 2 / 5;
    grid-row: 5;
    justify-self: start;
  }

  .article {
    justify-self: start;
    font-size: 20px;
    line-height: 1.4;
  }

  .options {
    font-size: 19px;
    margin-bottom: 5px;
    line-height: 1.2;
  }

  li {
    font-size: 20px;
    margin-bottom: 18px;
  }
}
@media screen and (min-width: 1521px) {
  .home_container {
    grid-template-rows: 50px 100px 40px;
  }
  .article {
    font-size: 22px;
    line-height: 1.4;
  }
  li {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
</style>
