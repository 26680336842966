<template>
  <div
    class="moon-btn pointer"
    @click="switchMode"
    :class="[
      u_store.darkMode ? 'darkOptions' : 'light',
      { moonBlur: u_store.isBlurred },
    ]"
    @touchstart.prevent="unBlurThenGo()"
  />
</template>

<script setup lang="ts">
import { uiStore } from '@/store/uiStore'
const u_store = uiStore()
function switchMode() {
  u_store.darkMode = !u_store.darkMode
}
function unBlurThenGo() {
  if (u_store.mask === true) return
  switchMode()
}
</script>

<style scoped>
@media screen and (max-width: 492px) {
  .moon-btn {
    width: 29px;
    height: 29px;
  }
}
@media screen and (min-width: 493px) and (max-width: 1220px) {
  .moon-btn {
    width: 40px;
    height: 40px;
  }
}
@media screen and (min-width: 1221px) {
  .moon-btn {
    width: 70px;
    height: 70px;
  }
}
@media screen and (min-width: 1520px) {
  .moon-btn {
    width: 82px;
    height: 82px;
  }
}
</style>
